import * as React from "react"

// markup
const SynTestHelpPage = () => {
  return (
    <main>
      <h1>SynTest Help</h1>
      <br/>
      <p>Select which stimulus groups you would like to test.</p>
      <p>Iterate through the test cases and receive your score (&lt; 1 is very good).</p>
      <p>Send your results to a researcher.</p>
    </main>
  );
}

export default SynTestHelpPage